import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { useBookingStore } from "../../hooks/useBookingStore";
import { Room } from "../../http/roomApi";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import Close from "../../ui/icon/close.svg?react";
import ResponsiveImage from "../../ui/ResponsiveImage";
import styles from "./RoomPhotos.module.css";

interface RoomPhotosProps {
  room: Room;
  isOpen: boolean;
  onClose: () => void;
}

const RoomPhotos = ({ room, isOpen, onClose }: RoomPhotosProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  return (
    <Dialog open={isOpen} onClose={onClose} className={styles.overlay}>
      <DialogPanel className={styles.dialog}>
        <div className={styles.inner}>
          <DialogTitle as="div" className={styles.header}>
            <Button
              layout="link"
              buttonProps={{
                onClick: onClose,
                "aria-label": i18n.general.close,
                className: styles.closeButton,
              }}
            >
              <Close className={styles.icon} />
            </Button>
          </DialogTitle>
          <div className={styles.photosWrapper}>
            <div className={styles.photos}>
              {room.photos.map((photo, index) => (
                <ResponsiveImage
                  key={index}
                  className={styles.photo}
                  alt={photo.description}
                  title={photo.description}
                  srcSet={photo.derivatives}
                  sizes="100vw"
                  lazyLoad={true}
                />
              ))}
            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  );
};

export default RoomPhotos;
