import useSWR from "swr";
import { Image, Price, Prices } from "../@types";
import { useBookingStore } from "../hooks/useBookingStore";
import { fetcher } from "./utils";

export interface Offers {
  offers: Offer[];
}

export interface Offer {
  room_type_id: string;
  room_index: number;
  available_rooms: number;
  cheapest_prices: Prices;
}

// TODO: replace with live url: `/properties/${propertyId}/offers`
const offersURL = "/offers.json";

export const useOffers = () => {
  return useSWR({ url: offersURL }, (opts) =>
    fetcher<Offers>(opts).then((res) => res.offers),
  );
};

export interface AutoOffers {
  auto_offers: AutoOffer[];
}

export interface AutoOffer {
  prices: Prices;
  rooms: AutoOfferRoom[];
}

export interface AutoOfferRoom {
  room_type_id: string;
  name: string;
  price: Price;
  occupancy: {
    adults: number;
    children?: number[];
  };
}

// TODO: replace with live url: `/properties/${propertyId}/auto_offers`
const autoOffersURL = "/auto-offers-de.json";

export const useAutoOffers = () => {
  const language = useBookingStore((state) => state.language);

  return useSWR({ url: autoOffersURL, language }, (opts) =>
    fetcher<AutoOffers>(opts).then((res) => res.auto_offers),
  );
};

export interface PortalPrices {
  portal_prices: PortalPrice[];
}

export interface PortalPrice {
  name: string;
  room_index?: number;
  icon: Image;
  price: Price;
}

// TODO: replace with live url: `/properties/${propertyId}/portal_prices`
const portalPricesURL = "/portal-prices.json";

export const usePortalPrices = () => {
  return useSWR({ url: portalPricesURL }, (opts) =>
    fetcher<PortalPrices>(opts).then((res) => res.portal_prices),
  );
};
